
import {
    Collapse,
    Dropdown,
    LazyLoad,
    initTE,
} from "tw-elements";

initTE({ LazyLoad, Collapse, Dropdown });


// Scroll Menu
window.onscroll = function (e) {
    var scrolMenuElement = document.getElementById("scroll-menu");
    if (this.scrollY > 700) {
        scrolMenuElement.classList.remove("hidden");
    } else {
        scrolMenuElement.classList.add("hidden");
    }
}
