var logoElement = document.getElementsByClassName('logo');
var logoWhite = [];
var logoDark = [];

for (let i = 0; i < (logoElement.length); i++) {
    logoWhite[i] = logoElement[i].dataset.logowhite;
    logoDark[i] = logoElement[i].dataset.logodark;
}


//dark theme switch
var themeToggleDarkIcon = document.getElementById('theme-toggle-dark-icon');
var themeToggleLightIcon = document.getElementById('theme-toggle-light-icon');
var darkModeSwitch = document.getElementById('dark-mode-switch');

// Change the icons inside the button based on previous settings
if (localStorage.getItem('color-theme') === 'dark' || (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    themeToggleLightIcon.classList.remove('hidden');
    document.documentElement.classList.add('dark');

    for (let i = 0; i < (logoElement.length); i++) {
        logoElement[i].src = logoWhite[i];
    }

} else {
    themeToggleDarkIcon.classList.remove('hidden');
    document.documentElement.classList.remove('dark');

    for (let i = 0; i < (logoElement.length); i++) {
        logoElement[i].src = logoDark[i];
    }
}


function setDark() {
    document.documentElement.classList.add('dark');
    localStorage.setItem('color-theme', 'dark');

    for (let i = 0; i < (logoElement.length); i++) {
        logoElement[i].src = logoWhite[i];
    }
}
function setLight() {
    document.documentElement.classList.remove('dark');
    localStorage.setItem('color-theme', 'light');

    for (let i = 0; i < (logoElement.length); i++) {
        logoElement[i].src = logoDark[i];
    }
}

var themeToggleBtn = document.getElementById('theme-toggle');
themeToggleBtn.addEventListener('click', function () {

    // toggle icons inside button
    themeToggleDarkIcon.classList.toggle('hidden');
    themeToggleLightIcon.classList.toggle('hidden');

    // if set via local storage previously
    if (localStorage.getItem('color-theme')) {
        if (localStorage.getItem('color-theme') === 'light') {
            setDark();
        } else {
            setLight();
        }

        // if NOT set via local storage previously
    } else {
        if (document.documentElement.classList.contains('dark')) {
            setLight();
        } else {
            setDark();
        }
    }
});
