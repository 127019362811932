// Initialize the service worker
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/serviceworker.js', {
        scope: '.'
    }).then(function (registration) {
        // Registration was successful
        console.log('OTODRIVER PWA: ServiceWorker registration successful with scope: ', registration.scope);
    }, function (err) {
        // registration failed :(
        console.log('OTODRIVER PWA: ServiceWorker registration failed: ', err);
    });
} else {
    console.log('OTODRIVER PWA: ServiceWorker not supported');
}
